import React from "react"
import Category from "../../templates/category"
import { withTranslation } from 'react-i18next';
import { graphql } from "gatsby"

export default withTranslation()(({ pageContext, data, t }) => {
  const { edges } = data.allMarkdownRemark
  return (
    <Category title={'Myynti | Vinkkejä tuloksellisempaan myyntiin'} 
    background="/images/blog_category1A.jpg"
    posts={edges} 
    meta={[
      {name:'description', content: 'Miten tehostaa myyntiä ja tehdä siitä tuloksellisempaa? Lue testatut vinkkimme ja startttaa myyntisi nousukausi nyt!'}
    ]}
    hero={<div><h1 className={"font_second semibold font40"}>Myynti – testatut vinkit tulokselliseen myyntiin</h1>
      <p className="font20 padding_top20 opacity9 text">
      Jokainen myyntityötä tehnyt tietää, että onnistunut myynti vaatii oikeanlaista tietoa, kokemusta ja työkaluja. Myynnin tekemistä ja myyntiprosessia kannattaakin tukea saatavilla olevalla tiedolla ja työkaluilla, ja koittaa välillä uusia keinoja. Tutustu testattuihin ja toimiviin vinkkeihimme, joilla saavutat tuloksekkaampaa myyntiä!
      </p></div>}>
      <p className="font20 padding_top20 opacity9 text red">
      </p>
    </Category>
  )
})

export const pageQuery = graphql `
  query {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { in: ["myynti"] }, date: { ne: null } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            path
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
          }
        }
      }
    }
  }
`
